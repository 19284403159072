<template>
    <div
      id="modal-whatsapp"
      class="modal"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <load-spinner v-if="isLoadSpinner"/>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Whatsapp History</h5>
          </div>
            <div class="modal-body" v-if="detailWhatsapp.createdDate">
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.createdDate') }}:</label>
                      <label class="col-lg-7 col-form-label">{{detailWhatsapp.createdDate | formatDate}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.lastModifiedDate') }}:</label>
                      <label class="col-lg-7 col-form-label">{{detailWhatsapp.lastModifiedDate | formatDate}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.sendStatus') }}:</label>
                      <label class="col-lg-7 col-form-label">{{detailWhatsapp.deliveryStatus}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.recipient') }}:</label>
                      <label class="col-lg-7 col-form-label">{{detailWhatsapp.recipient}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.message') }}:</label>
                      <label class="col-lg-7 col-form-label">{{detailWhatsapp.message}}</label>
                  </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-clean btn-bold btn-upper btn-font-md"
                @click="clearData()"
              >Close</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {mapState, mapMutations, mapActions} from 'vuex'
  import LoadSpinner from "./../../_general/LoadSpinner";
  
  export default {
    components: {
      LoadSpinner
    },
    props: {
      idWhatsapp: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
          // dataFormS: {}
      };
    },
    computed: {
       ...mapState('NotifikasiStore', {
        detailWhatsapp: state => state.detailWhatsapp,
        isLoadSpinner: state => state.isLoadSpinner
      })
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("DD-MMM-YYYY HH:mm:ss");
        }
    },
    methods: {
      clearData() {
          $("#modal-whatsapp").modal('hide');
          this.changeShowModal(false)
      },
       ...mapMutations({
        changeShowModal: 'NotifikasiStore/SET_MODAL_WHATSAPP'
      }),
      ...mapActions({
        getByIdWhatsapp: 'NotifikasiStore/GET_HISTORY_DETAIL_WHATSAPP'
      }),
    },
    async mounted() {
      var vx = this;
      $("#modal-whatsapp").modal();
       await this.getByIdWhatsapp(this.idWhatsapp)
      // console.log(this.detailWhatsapp)
    }
  }
  </script>
  